import type DateStr from '@consumer/types/DateStr'
import type DateTimeStr from '@consumer/types/DateTimeStr'
import {
  compareAsc,
  format as dateFnsFormat,
  isToday as dateFnsIsToday,
  parse,
  parseJSON as dateFnsParseJSON,
} from 'date-fns'
import { formatInTimeZone as dateFnsFormatInTimezone } from 'date-fns-tz'

export {
  addDays,
  subDays,
  addMonths,
  subMonths,
} from 'date-fns'

export const DEFAULT_LONG_DATE_FORMAT = 'E, MMM d, yyyy'
export const DEFAULT_SHORT_DATE_FORMAT = 'M/d'
export const ISO_FORMAT = 'yyyy-MM-dd'

interface FormatDateOptions {
  format: string
  showNow?: boolean
  timezone?: string
}

export const isDateStr = (dateStr: string) => /^\d{4}-\d{2}-\d{2}$/.test(dateStr)

export const formatDate = (
  date: DateTimeStr | DateStr | number | null | undefined,
  {
    format,
    showNow,
    timezone,
  }: FormatDateOptions = {
    format: DEFAULT_LONG_DATE_FORMAT,
  },
): string => {
  try {
    if (!date) {
      return ''
    }

    let dateToFormat = date
    if (typeof dateToFormat === 'string') {
      let format = null
      if (/^\d{4}-\d{2}-\d{2}$/.test(dateToFormat)) {
        format = ISO_FORMAT
      }
      dateToFormat = parseDate(dateToFormat, format)
    }

    if (showNow && isToday(dateToFormat)) {
      return 'Now'
    }
    else {
      if (timezone) {
        return dateFnsFormatInTimezone(dateToFormat, timezone, format)
      }
      else {
        return dateFnsFormat(dateToFormat, format)
      }
    }
  }
  catch (e) {
    return `Error: ${e} - ${date}`
  }
}

export const parseDate = (date: string | Date, format?: string | null) => {
  if (date instanceof Date) {
    return date
  }
  else if (format) {
    return parse(date, format, new Date())
  }
  else {
    return dateFnsParseJSON(date)
  }
}

export const isToday = (date: Date | string | number | undefined) => {
  if (typeof date === 'string') {
    const parsedJSONDate = dateFnsParseJSON(date)
    if (!isNaN(parsedJSONDate.valueOf())) {
      date = parsedJSONDate
    }
    else if (isDateStr(String(date))) {
      date = parseDate(date, 'yyyy-MM-dd')
    }
    else {
      return date === 'Today' || date === 'Now'
    }
  }

  if (date) {
    return dateFnsIsToday(date)
  }
  else {
    return false
  }
}

export const isYesterday = (date: Date) => compareAsc(date, new Date()) === -1
